import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: "Q°emotion - Conditions d'utilisation",
  keywords: [],
  description:
    'Q°emotion est un outil d’analyse sémantique et émotionnelle qui analyse vos commentaires clients afin de réduire votre taux d’attrition. Lisez nos termes et conditions.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "termsConditions" }, lang: { eq: "fr" } }
    ) {
      rawMarkdownBody
    }
  }
`;
